import React from "react";

function TCcomponent() {
  return (
    <div className="min-h-screen white py-12 mt-[-4rem] md:mt-[-7rem]  max-w-6xl mx-auto">
      <div className="max-w-7xl bg-white rounded-xl md:p-8">
        <h1 className="font-extrabold md:mx-12 text-3xl  mx-4 md:text-4xl md:pt-0 pt-10 md:mt-6">Bookmee</h1>
        <h2 className="md:text-2xl text-xl my-2 md:mx-12 mx-4">Terms & Conditions</h2>
        <div className="mx-12 my-12 text-[#667085]">
        <p>
          You will receive an 'OTS Journey Acknowledgement' email for the
          journey(s) you have paid for. You are responsible for checking that
          the details received to us are correct. </p> <br />
          <p>
          You will receive a 'Journey
          Details' email when the journey has been assigned to a driver. This
          will contain the pick-up instructions and the driver's telephone
          number. Airport Taxi Travel Solutions Ltd will not refund you if you
          have forgotten to take this with you. </p> <br />
          <p>
          Airport Taxi Travel Solutions
          Ltd does not accept any responsibility in any way for missed flights
          for whatever reason i.e. traffic delays, accidents, breakdowns, severe
          weather conditions or any unforeseen circumstances. </p><br />
          <p>
          We advise
          passengers to plan to arrive at the airport 2 hours prior to flight
          departure to allow for possible unpredicted delays en route to or from
          the airport. Onward Travel Solutions Ltd will not take responsibility
          for any passengers missing their flight if two hours check-in time was
          not allowed. </p><br />
          <p>
          You are free of course to arrange to get to the airport
          for a time of less than 2 hours prior to flight departure, however
          Onward Travel Solutions Ltd accepts no responsibility for any missed
          flight due to this. </p><br />
          <p>
          All passengers are advised to have adequate travel
          insurance prior to booking. </p><br />
          <p>
          No responsibilities for costs are to be
          refunded to any passengers who do not wait for their driver and take
          alternative transport. </p><br />
          <p>
          Airport Taxi Travel Solutions Ltd does not
          accept any responsibility in any way if the passenger/luggage
          requirements exceed the capacity of the vehicle booked. If you are
          unsure about the capacity of the vehicle booked, please contact Onward
          Travel Solutions customer services team immediately. </p><br />
          <p>
          For passenger
          safety, Taxi Licensing regulations require all luggage to be securely
          fastened in the boot of the vehicle, please ensure to take this into
          account when choosing a vehicle. </p><br />
          <p>
          Airport Taxi Travel Solutions Ltd
          reserves the right to refuse carriage of animals which were not agreed
          at the point of booking. All animals must be secured in a suitable
          transport box/crate. Onward Travel Solutions Ltd accepts no
          responsibility for costs incurred from a failure to abide by these
          terms. </p><br />
          <p>
          The exact route of your journey is down to the driver's
          discretion on the day of travel, alternate routes may be requested and
          will be assessed by the driver on a case by case basis. </p><br />
          <p>
          Please ensure
          you give us your arrival times and day in to the UK and not your
          departing information. </p><br />
          <p>
          If your flight has any serious delays, please
          inform us as soon as possible. </p><br />
          <p>
          Airport Taxi Travel Solutions Ltd uses
          their own transport wherever possible but does use third party
          companies where appropriate. </p><br />
          <p>
          Airport Taxi Travel Solutions Ltd
          reserves the right to provide an upgraded car type from the original
          selected if your chosen vehicle is unavailable. </p><br />
          <p>
          Reservations made for
          service on the following timings and dates will be subject to an
          additional 50% surcharge on published prices: 18:00 24th December to
          23:59 26th December, 18:00 31st December to 23:59 1st January, other
          days may also be affected. </p><br />
          <p>
          All card payments are processed securely by
          WISE. Airport Taxi Travel Solutions Ltd do not process or retain card
          details, except for selected metadata returned to us by Sage Pay (card
          type, expiry date, etc). This service uses the latest 3DSv2 security
          checks for strong customer authentication. </p><br />
          <p>
          During the payment process,
          you may be offered the opportunity to 'save' your card for easier
          future use. This optional facility is offered by WISE, who will retain
          your card details securely on behalf of Onward Travel Solutions Ltd;
          typically until the card expires. Onward Travel Solutions may instruct
          WISE to delete these saved details prior to the card expiry if your
          account with us is deemed to be inactive. Note that you will still
          need to provide some security details when using a saved card. You may
          delete saved cards at any time from your online account area, or by
          contacting us. </p><br />
          <p>
          Customer-initiated transactions (CIT) performed on our
          public website are initialized with the ability to be 'repeated',
          subject to relevant security checks. This could be for a different
          amount to the original payment. For example, if you were to request a
          'Meet & Greet' service be added to your journey after your booking is
          placed, with your permission we may acquire the additional fee for
          this service from the original payment method without needing to
          re-take card details. You will always have the opportunity to choose
          an alternate payment option. Full or partial refunds can also be
          applied to the original payment method to cover fare reductions or
          cancellations; however please note that fees may apply for
          cancellations, depending on the circumstances (see <span className="font-bold">'Cancellations'</span> in
          our <span className="font-bold">'Policies'</span> section). </p><br />
          <p>
          All passengers are responsible for any cost
          incurred to return a vehicle to working order (repairs or professional
          cleans) where the damage to the vehicle by passengers exceeds
          reasonable wear and tear.
          </p>
        </div>
      </div>
    </div>
  );
}
export default TCcomponent;
