import React from "react";
function PoliciesandNotices() {
  return (
    <div className="min-h-screen white py-12 max-w-6xl  mx-auto">
      <div className="max-w-7xl  bg-white mt-12 border rounded-xl md:p-8">
        <h1 className="font-extrabold md:mx-12 text-3xl  mx-4 mt-4 md:text-4xl">Bookmee</h1>
        <h2 className="md:text-2xl text-xl my-2 md:mx-12 mx-4">Policies & Notices</h2>


        <h1 className="font-bold mx-8 my-3 text-xl text-[#FE9901]">
          Waiting Time
        </h1>
        <div className="mx-12 text-lg text-[#667085]">
          <p>
            On journeys collecting from and airport, as standard, onward Travel
            Solutions LTD allows all passengers 1 hour maximum from the time the
            flight actually lands to meet with their driver. After this, waiting
            time is charged, regardless of reason, at $20/hr pro rata.
          </p>
          <p className="my-2 text-lg">
            Onward Travel Solutions LTD will honour requests for a "deferred"
            collection time, e.g. [X] minutes after flight landing. However,
            customers requesting this are not entitled to any additional waiting
            time beyond the requested period. No compensation will be offered if
            the passenger(s) is ready earlier than planned and has to wait until
            the scheduled collection time for the driver to arrive.{" "}
          </p>
          <p className="my-5 text-lg">
            Onward Travel Solutions Ltd will assume you wish for a 'standard'
            collection unless otherwise informed in writing in advance of the
            journey by email to sales@ots-system.net{" "}
          </p>
          <p className="text-lg">
            Waiting time for all other journeys will be changed at $20/hr pro
            rata, either from the scheduled collection time or for any stop
            en-route.
          </p>
          <h1 className="font-bold my-5 text-xl text-[#FE9901]">Tolls</h1>
          <p className="text-lg">All Tolls are included in the instant online quotes. </p>
          <h1 className="font-bold my-5 text-xl text-[#FE9901]">Amendments</h1>
          <p className="text-lg">
            Any amendment must be made through the office either via the
            website, telephone or e-mail.In this way confirmations are sent out
            and the journey is insured.
          </p>
          <h1 className="font-bold my-5 text-xl text-[#FE9901]">Re-Booking</h1>
          <p className="text-lg">
            All bookings must be made through the office either via the website
            , telephone or e-mail. In this way confirmations are sent out and
            the journey is insured.
          </p>
          <h1 className="font-bold my-5 text-xl text-[#FE9901]">
            Cancellations
          </h1>
          <p className="text-lg">
            Onward Travel Solutions Ltd will accept any cancellation as long as
            24 hours notice is provided. There will be a $7 or 10%(whichever is
            higher) administration/ transaction charge per journey. All
            Cancellations must be made via an email to which you will receive
            confirmation by us.{" "}
          </p>
          <p className="text-lg my-2">
            If you do not receive an email from Onward Travel Solutions Ltd
            confirming the cancellation, then we have not received it. In this
            case please call our out of hours number which is 01934 744171.
          </p>
          <p>Refunds will not be issued in the following circumstances:</p>
          <p className="my-2 text-lg">
            - No refund is made if the passenger does not show up for the
            pre-paid journeys.{" "}
          </p>
          <p>
            - No refund is made for the cancellation of a booking with less than
            24 hours notice provided or afterwards.{" "}
          </p>
          <p className="mt-2 text-lg">
            - All other circumstances where a refund may be possible should be
            addressed directly with Onward Travel Solutions Ltd's customer
            services
          </p>
          <h1 className="text-lg font-bold my-5 text-xl text-[#FE9901]">Complaints</h1>
          <p>
            Any complaints regarding service should be raised in writing with
            our office, preferably by reply to your confirmation email. All
            complaints must be submitted within 30 days of the event giving rise
            to the complaint.
          </p>
          <p className="my-6 text-lg">
            Please note that some calls may be recorded for quality and training
            purposes.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PoliciesandNotices;
